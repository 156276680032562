@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import "auth";

.login-logo{ 
  background: transparent;
  border: none;
}


// TABLE LAST COLUMN FIXED



#admin-list {
  overflow-x: auto;
}

#admin-list tr > th:last-child,
#admin-list tr > td:last-child {
  position: sticky;
  right: 0;
  z-index: 2; /* Make sure it stays on top of the other elements */
}

#admin-list tr:nth-child(odd) td {
   background: #ffffff; /* Assuming $white is a variable, replace with actual color if not using a preprocessor */
}

#admin-list tr:nth-child(even) td {
   background: #e5e5e5; /* Replace with the correct color value for $gray-200 */
}


// SPINNER

.spinner {
  max-width: 400px;
  margin: auto;
  display: flex;
}

.loading-spinner-lg{
  max-width: 500px !important;
}

.loading-spinner-sm{
  max-width: 200px !important;
}

.loading-spinner-xsm{
  max-width: 50px !important;
}

#overlay-small {
position: inherit;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0,0,0,0.5);
z-index: 6;
cursor: pointer;
}

#overlay {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0,0,0,0.5);
z-index: 6;
cursor: pointer;
}

$navbar-font-primary: 'Poppins',Arial, sans-serif;
$navbar-primary: #866ec7;


// sidebar

.sidebar {
  position: fixed;
  top: 0;
  overflow-y: auto;
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.sidebar-margin {
  margin-bottom: 4rem !important;  /* Set the margin for the last li element */
}

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.btn-toggle-nav .active {
  background-color: #d2f4ea;
}

/*
 * Navbar
 */

 .navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(39, 245, 214, 0.8);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.footer-sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.close-button {
  padding: 0 3px;
  margin: 1%;
  max-width: 15px;
  max-height: 25px;
}

.colour-swatch {
  padding: '5px';
  background: '#fff';
  border-radius: '1px';
  box-shadow: '0 0 0 1px rgba(0,0,0,.1)';
  display: 'inline-block';
  cursor: 'pointer';
}
.colour-popover {
  position: 'absolute';
  z-index: '2';
};
.colour-cover {
  position: 'fixed';
  top: '0px';
  right: '0px';
  bottom: '0px';
  left: '0px';
}

.relationship-required {
  border: 3px dotted red;
}


.dropdown-menu > a:hover {
  background-image: none;
  background-color: rgb(209, 240, 232);
}


// upload form 

.file-upload {
  background-color: #ffffff;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.drag-text {
  text-align: center;
}

.border-err {
  border-color: red !important;
}


.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #2EC5E8;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #2EC5E8;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #2EC5E8;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  border: 4px dashed #2EC5E8;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #2EC5E8;
  border: 4px dashed #ffffff;
}
/* Remove default bullets */
ul, #myUL {
  list-style-type: none;
}

/* Remove margins and padding from the parent ul */
#myUL {
  margin: 0;
  padding: 0;
}

/* Style the caret/arrow */
.caret {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
  transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

.ml-0 {
  margin-left: 0 !important;
};

.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  margin-left: -5%;
}


.onoffswitch {
  position: relative;
  width: 100%;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block; overflow: hidden; cursor: pointer;
  border: 2px solid #999999; border-radius: 5px;
}

.onoffswitch-inner {
  display: block; width: 200%; margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s; -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s; transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
  font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "On";
  padding-left: 10px;
  background-color: #2FCCFF; color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "Opt";
  padding-right: 10px;
  background-color: #EEEEEE; color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block; 
  width: 20%; margin: 0px;
  max-height: 33px;
  background: #FFFFFF;
  border: 2px solid #999999; border-radius: 5px;
  position: absolute; top: 0; bottom: 0; right: 80%;
  -moz-transition: all 0.3s ease-in 0s; -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s; transition: all 0.3s ease-in 0s; 
  background-image: -moz-linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
  background-image: -webkit-linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
  background-image: -o-linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
  background-image: linear-gradient(center top, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px; 
}


.checkbox-wrapper-16 {
  max-width: 7rem !important;
}


.checkbox-wrapper-16 * {
  box-sizing: border-box;
  max-width: 7rem !important;
}

.checkbox-wrapper-16 .checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #22ff94;
  border-color: #22ff94;
}

.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: black;
}

.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile {
  border-color: #c8f0f7;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-wrapper-16 .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 7rem;
  min-height: 7rem;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background: linear-gradient(135deg, #ffffff, #f0f9ff); /* Light gradient */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.checkbox-wrapper-16 .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #d2d8e7;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.checkbox-wrapper-16 .checkbox-tile:hover {
  border-color: #22ff94;
}

.checkbox-wrapper-16 .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-wrapper-16 .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}

.checkbox-wrapper-16 .checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}

.checkbox-wrapper-16 .checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
